@import url(https://fonts.googleapis.com/css2?family=Sintony:wght@400;700&family=Slabo+27px&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #113;
}

h1,
h2,
h3 {
  font-family: 'Source Sans Pro';
}

h4,
p {
  font-family: 'Sintony';
}

h1 {
  color: #f5f5f5;
  font-size: 120px;
  font-weight: 900;
  line-height: 150px;
  margin: 0;
}

h2 {
  color: #f5f5f5;
  font-size: 64px;
  line-height: 80px;
  margin: 0;
}

h3 {
  font-size: 48px;
  font-weight: 900;
  line-height: 60px;
  margin: 0;
}
h4 {
  margin: 0;
  font-size: 24px;
  color: #666;
}

p {
  color: #f5f5f5;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
}

a {
  text-decoration: none;
}

a:active {
  color: 'white';
}

